import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Assuming you're using React Router for routing
import axios from 'axios';
const api='https://mindcoadmin.v4masters.com/public/index.php'
function CaseStudy() {
    const {id} = useParams();
    const [project,setProject] = useState([]);
	function getdata(){
		axios.get(`${api}/getprojectdata/${id}`).then((response)=>{
			console.log(response.data[0]);
			setProject(response.data[0])
		}).catch((error)=>{
			console.log(error);
		})
	}
		
	useEffect(()=>{
        getdata();
	},[])

  return (
    <>
<main>
  <div className="container">
    <section id="case-study-details" className="py-5 text-center text-md-start">
      <div className="row">
        <div className="col-md-7">
          <h1 className="font-outfit pb-2">Case Study</h1>
          <h4 className="fs-1">Website Redesign</h4>
          <div className="border-bottom border-dark border-2 mt-4 mb-5 mx-auto mx-md-0 sep-line" />
          <h3 className="fs-2 mt-4">{project.casestudy_title}</h3>
          <p className="fs-3"><a href={project.cs_url} target='_blank' rel="noreferrer"  className="link-dark">{project.cs_url}</a></p>
          <h3 className="fs-2">About Project</h3>
          <p>{project.cs_project}</p>			
        </div>	
        <div className="col-md-5">
          <img src={`https://smarto.s3.ap-south-1.amazonaws.com/mindcode/portfolio/${project.cs_img}`} alt="portfolio-image" className="rounded-4 d-block w-100 border-end border-bottom border-5 border-primary" />
        </div>
        <div className="row g-4">
          <div className="col-md-6">
            <div className="border border-light h-100 bg-light rounded-4 p-3">
              <h4 className="fs-2 text-primary fw-semibold pb-2">Objective</h4>
              <p>{project.cs_objective}</p><p>
              </p></div>
          </div>
          <div className="col-md-6">
            <div className="border border-light h-100  bg-light rounded-4 p-3">
              <h4 className="fs-2 text-primary  fw-semibold pb-2">Design and User Experience</h4>
              <p>{project.cs_exp}</p><p>
              </p></div>
          </div>
        </div>
        <div className="row g-4">
          <div className="col-md-6">
            <div className="border border-light h-100  rounded-4 p-3">
              <h4 className="fs-2 text-primary fw-semibold pb-2">Functionality</h4>
              <p>{project.cs_function}</p><p>
              </p></div>
          </div>
          <div className="col-md-6">
            <div className="border border-light h-100  rounded-4 p-3">
              <h4 className="fs-2 text-primary fw-semibold pb-2">Content Strategy</h4>
              <p>{project.cs_strategy}</p><p>
              </p></div>
          </div>
        </div>
        <div className="row g-4">
          <div className="col-md-12">
            <div className="border border-light h-100   rounded-4 p-3">
              <h4 className="fs-2 text-primary fw-semibold pb-2 text-primary">Marketing and Outreach</h4>
              <p>{project.cs_market}</p><p>
              </p></div>
          </div>
        </div>
        <div className="row g-4">
          <div className="col-md-6">
            <div className="border border-light h-100 bg-light rounded-4 p-3">
              <h4 className="fs-2 text-primary fw-semibold pb-2">Results and Impact</h4>
              <p>{project.cs_result}</p><p>
              </p></div>
          </div>
          <div className="col-md-6">
            <div className="border border-light bg-light rounded-4 h-100 p-3">
              <h4 className="fs-2 text-primary  fw-semibold pb-2">Future Directions</h4>
              <p>{project.cs_future}</p><p>
              </p></div>
          </div>
        </div>
        <div className="row g-4">
          <div className="col-md-12">
            <div className="border  border-light bg-black text-white conclusion-section p-3">
              <h4 className="fs-2 fw-semibold pb-2 text-white">Conclusion</h4>
              <p>
              {project.cs_conclusion}</p><p>
              </p></div>
          </div>
        </div>
        <div className="mt-5">
          <h4 className="fs-2 fw-semibold text-primary">Final Designs</h4>
          <p>Trendy Apparel Inc. approached us to develop a modern, responsive e-commerce platform that could handle a large catalog of trendy fashion products. The goal was to create a user-friendly and visually appealing website that would enhance their online presence and boost sales.</p>
        </div>
        <img src={`https://smarto.s3.ap-south-1.amazonaws.com/mindcode/portfolio/${project.cs_screen_shot}`} className="px-md-5 mt-4 img-fluid" />
      </div>
    </section>
    <section id="lets-talk" className="bg-primary p-5 my-5 rounded-5 text-white position-relative">
      <h2>DO YOU NEED ANY DESIGN ? <span className="d-block">LET'S TALK</span></h2>
      <a href="https://mindcodelab.in/contact" className="btn btn-light btn-lg mt-4 rounded-pill">Contact Us</a>
    </section>
  </div>
</main>

    </>
  )
}

export default CaseStudy;