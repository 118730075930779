import React, { useEffect, useState } from 'react';
import portfolioImage from '../assets/images/portfolio-image.png';
import visualsImage from '../assets/images/visuals.jpg';
import portfolioAbbeyImage from '../assets/images/portfolio.jpg';
import abbeyLogo from '../assets/images/abbey.png';
import figmaLogo from '../assets/images/figma-logo.png';
import htmlIcon from '../assets/images/html.png';
import cssIcon from '../assets/images/css-icon.png';
import reactIcon from '../assets/images/react-icon.png';
import jsIcon from '../assets/images/js.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useParams } from 'react-router';
import axios from 'axios';
import { Link, NavLink } from 'react-router-dom';

function PortfolioDetails(){

	const {id} = useParams();
    const [project,setProject] = useState([]);
	function getdata(){
		axios.get(`https://mindcoadmin.v4masters.com/public/index.php/getprojectdata/${id}`).then((response)=>{
			console.log(response.data[0]);
			setProject(response.data[0])
		}).catch((error)=>{
			console.log(error);
		})
	}
		
	useEffect(()=>{
        getdata();
	},[])
  return (
	<>
    <main>
		<div class="container">
			<section id="portfolio-details" class="py-5">
				<div class="row">
					<div class="col-md-7">
						<h1 class="font-outfit">{project.project_title}</h1>
						<h4 class="fs-1">{project.sub_title}</h4>
						<div class="border-bottom border-dark border-2 mt-4 mb-5 sep-line"></div>
												
						<h3 class="fs-2 mt-4">Client Name: {project.client_name}</h3>
						<p class="fs-3"><a href={project.project_url} target='_blank' rel="noreferrer"   class="link-dark">{project.project_url}</a></p>
						
						<h3 class="fs-2">Project Summary</h3>
						<p>{project.summary}</p>
						
						{/* <h3 class="fs-2 text-dark mt-5">Project Duration</h3>
						<p>{project.duration}</p> */}
						
						{/* <h3 class="fs-2 text-dark mt-4 mb-3">Tools Used</h3>						
						<ul class="d-flex ">
							<li class="list-inline-item p-3">
								<img src={figmaLogo} class="icons d-block mx-auto"/>Figma
							</li>
							<li class="list-inline-item  p-3 ">
								<img src={htmlIcon} class="icons d-block mx-auto"/>HTML
							</li>
							<li class="list-inline-item  p-3">
								<img src={cssIcon} class="icons d-block mx-auto"/>CSS
							</li>
							<li class="list-inline-item  p-3">
								<img src={reactIcon} class="icons d-block mx-auto"/>React
							</li>
							<li class="list-inline-item  p-3">
								<img src={jsIcon} class="icons d-block mx-auto"/>JavaScript
							</li>
						</ul>						 */}
					</div>					
					<div class="col-md-5">
						<img src={`https://smarto.s3.ap-south-1.amazonaws.com/mindcode/portfolio/${project.project_img}`} alt="portfolio-image" class="rounded-4 d-block w-100 border-end border-bottom border-5 border-primary"/>
					</div>
				</div>
			</section>
			
			<section id="visuals">			
				<h3 class="fs-2 text-dark text-center mb-5 lr-pattren-line"><span class="left-line"></span> Visuals <span class="right-line"></span></h3>
				
					
				<img src={`https://smarto.s3.ap-south-1.amazonaws.com/mindcode/portfolio/${project.visual_img}`} alt='im' class="d-block mx-auto img-fluid mb-5 px-5"/>
			</section>
			
			<section id="case-study">	
				<h3 class="fs-2 text-dark text-center mb-5 lr-pattren-line"><span class="left-line"></span> Case Study <span class="right-line"></span></h3>
				
				<div class="row g-4">
					<div class="col-md-6">
						<div class="position-relative portfolio-abbey">
							<img src={`https://smarto.s3.ap-south-1.amazonaws.com/mindcode/portfolio/${project.cs_img}`} alt="Abbey" class="w-100 abbey-img"/>
							<div class="position-absolute top-50 start-50 translate-middle partner-logo"><img src={`https://smarto.s3.ap-south-1.amazonaws.com/mindcode/portfolio/${project.cs_logo}`} alt="Abbey Logo" class="d-block mx-auto img-fluid"/></div>
						</div>
					</div>
					<div class="col-md-6">
						<h3 class="fs-2">{project.casestudy_title}</h3>
						<p class="mt-4">{project.case_study}</p>
						<Link to={`/case_study/${id}`} onClick={() => window.scrollTo({ top: 0, behavior: 'instant' })} class="btn btn-primary btn-lg mt-4 rounded-pill">Read More</Link>
					</div>
				</div>
			</section>
			
			<section id="testimonial">
				<h3 class="fs-2 text-dark text-center my-5 lr-pattren-line"><span class="left-line"></span> Testimonial <span class="right-line"></span></h3>
				
				<div class=" mx-auto pt-4">
					<div class="bg-light text-center p-4 py-lg-5 position-relative bubble-shape">
						<p>"The team delivered a sleek, user-friendly e-commerce platform that beautifully showcases our fashion products. We've seen a significant increase in sales and customer engagement. Their professionalism and dedication were outstanding. Highly recommend!"</p>
						<h3 class="fs-2 pt-3"> Trendy Apparel Inc</h3>
						<ul class="list-inline mb-0 fs-6">
							<li class="list-inline-item"><i class="bi bi-star-fill text-warning"></i></li>
							<li class="list-inline-item"><i class="bi bi-star-fill text-warning"></i></li>
							<li class="list-inline-item"><i class="bi bi-star-fill text-warning"></i></li>
							<li class="list-inline-item"><i class="bi bi-star-fill text-warning"></i></li>
							<li class="list-inline-item"><i class="bi bi-star-fill text-secondary"></i></li>
						</ul>
					</div>
				</div>
			</section>
			
			<section id="lets-talk" class="bg-primary p-5 my-5 rounded-5 text-white position-relative">
				<h2>DO YOU NEED ANY DESIGN ? <span class="d-block">LET'S TALK</span></h2>
				<a href="https://mindcodelab.in/contact" class="btn btn-light btn-lg mt-4 rounded-pill">Contact Us</a>
			</section>
			
		</div>
    </main>
	</>
  );
};

export default PortfolioDetails;
